import './index.scss'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import LocomotiveScroll from 'locomotive-scroll'

let ismobile = $(window).width() > 1024 ? false : true
let scroller

$(window).resize(function(){
  ismobile = $(window).width() > 1024 ? false : true;
  init()
})

$(document).ready(function(){
  init()

  var $video = $('.section1 video')
  $video.each(function(index){
    this.addEventListener('play', function () { //播放开始执行的函数
      $(this).closest('.video-c').find('.play').addClass('fadeOut')
    });
    this.addEventListener('pause', function () { //暂停开始执行的函数
      $(this).closest('.video-c').find('.play').removeClass('fadeOut')
    });
  })
  $('.section1 .video-c .play').on('click',function(){
    let $parent = $(this).closest('.video-c')
    $parent.find('video')[0].play()
    $(this).hide()
  })
})


function init(){
  if(!ismobile && (typeof scroller) == 'undefined'){
    // pc端
    scroller = new LocomotiveScroll({});
    window.addEventListener('showmenubg', (e) => {
      const { target, progress } = e.detail;
      if(progress > 0.5){
        $('.header').addClass('scroll')
      }else{
        $('.header').removeClass('scroll')
      }
    });
    window.addEventListener('linebg', (e) => {
      const { target, progress } = e.detail;
      $(target).css("transform","scale(1," + progress + ")")
    });
  }else{
    //移动端
    $('.section1 [data-scroll]').addClass('is-inview')
    $('.section2 [data-scroll]').addClass('is-inview')
    //页面滚动监听
    $(document).scroll(function () {
      let scroH = $(document).scrollTop(); //滚动高度
      let viewH = $(window).height(); //可见高度
      let contentH = $(document).height(); //内容高度

      if (scroH > 200) {
        //距离顶部大于200px时
        $('.header').addClass('scroll')
      }else{
        $('.header').removeClass('scroll')
      }
      
      $('[data-scroll]').each(function(){
        if(scroH > $(this).offset().top - viewH){
          $(this).addClass('is-inview')
        }else{
          $(this).removeClass('is-inview')
        }
      })

      // 背景竖线
      let bgH = $('.section2 .cont4').outerHeight()
      let bgTopstart = $('.section2 .cont4').offset().top - viewH
      let bgTopend = $('.section2 .cont4').offset().top - viewH + bgH
      let scaleY =  (scroH - bgTopstart) / bgH
      if(scroH > bgTopstart && scroH < bgTopend){
        $('.section2 .cont4 .bg').css('transform','scaleY('+ scaleY +')');
      }
    });
  }
}

$(document).scroll(function(){
  var $video = $('.section1 video')[0]
  $video.play()
})